import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
export default {
  addPostil: function addPostil() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post('/app-doc-center/v1/prj-annotation/add', data).then(function (res) {
      return res;
    });
  },
  queryStage: function queryStage(fileID) {
    var docVersionId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    return request.post("/app-doc-center/v1/doc/query-stage?id=".concat(fileID, "&docVersionId=").concat(docVersionId, "&isFolder=", 0));
  },
  queryAllList: function queryAllList() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post('/app-doc-center/v1/prj-annotation/query', data);
  },
  addCommentUrl: function addCommentUrl() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post('/app-doc-center/v1/prj-annotation/add-comment', data);
  },
  updateStatus: function updateStatus() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post('/app-doc-center/v1/prj-annotation/update', data);
  },
  updateRemark: function updateRemark() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post('/app-doc-center/v1/prj-annotation/update', data);
  },
  deletePostil: function deletePostil(PostilID) {
    return request.get("/app-doc-center/v1/prj-annotation/delete/".concat(PostilID));
  },
  deletePostilImage: function deletePostilImage(PostilID, imageID) {
    return request.get("/app-doc-center/v1/prj-annotation/delete-screenshot/".concat(PostilID, "/").concat(imageID));
  }
};