import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
export default {
  getChannel: function getChannel(prjId) {
    return request.get("/app/v1/prj-home-page/get-one-prj?prjId=".concat(prjId)).then(function (res) {
      return res;
    });
  },
  getAddInit: function getAddInit(prjId, queCategory) {
    return request.get("/app-doc-center/v1/drawing/problem/add-init?prjId=".concat(prjId, "&queCategory=").concat(queCategory));
  },
  queryDict: function queryDict(prjId) {
    //正向图纸 查询字典
    return request.get('/app-doc-center/v1/qm-comment/dic-init?prjId=' + prjId).then(function (res) {
      return res;
    });
  },
  queAdd: function queAdd(param) {
    return request.post("/app-doc-center/v1/problem/manage/cad-add", param);
  },
  queAdd_gml: function queAdd_gml(param) {
    return request.post("/app-doc-center/v1/problem/manage/cad-add-gml", param);
  },
  modelAdd: function modelAdd(param) {
    return request.post("/app-doc-center/v1/qm-comment/insert", param);
  },
  modelAdd_gml: function modelAdd_gml(param) {
    return request.post("/app-doc-center/v1/qm-comment/insert-gml", param);
  },
  uploadBaseImg: function uploadBaseImg(params) {
    return request.post('/app-doc-center/v1/drawing/problem/img-upload-gml', params).then(function (res) {
      return res;
    });
  },
  uploadBaseImg_nogml: function uploadBaseImg_nogml(params) {
    return request.post('/app-doc-center/v1/drawing/problem/img-upload', params).then(function (res) {
      return res;
    });
  },
  getQueDetail: function getQueDetail(questionId) {
    return request.get("/app-doc-center/v1/problem/manage/cad-detail/".concat(questionId));
  },
  problemDetail_gml: function problemDetail_gml(qMId) {
    //查询项目详情
    return request.get("/app-doc-center/v1/problem/manage/cad-detail-gml/".concat(qMId)).then(function (res) {
      return res;
    });
  },
  getCadQueDetail: function getCadQueDetail(questionId) {
    return request.get("/app-doc-center/v1/qm-comment/query-detail/".concat(questionId));
  },
  problemDetail3D_gml: function problemDetail3D_gml(params) {
    //查询项目详情 
    return request.get("/app-doc-center/v1/qm-comment/query-detail-gml/".concat(params)).then(function (res) {
      return res;
    });
  },
  saveComment: function saveComment(param) {
    return request.post("/app/v1/comment/save-gml", param);
  },
  queryQuestionCheckList: function queryQuestionCheckList(folderId) {
    return request.get("/app-doc-center/v1/problem/manage/query-question-check-list-gml?folderId=".concat(folderId));
  }
};